<template>
    <div class="user" :class="theme">
        <el-dropdown trigger="click" @command="checkMenu" v-if="userInfo">
            <span class="user-icon el-dropdown-link">
                <img :src="userInfo.avatarUrl && $store.state.assetsUrl + userInfo.avatarUrl ||
                    DefaultAvatar
                    " alt="" />
            </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                    <div class="avatar">
                        <img :src="userInfo.avatarUrl && $store.state.assetsUrl + userInfo.avatarUrl ||
                            DefaultAvatar
                            " alt="" />
                        <span>{{ userInfo.nickName }} </span>
                        <span class="user-vip__icon"><img :src="userInfo.type == 1 ? VipBigIcon : VipIcon" alt="" /></span>
                    </div>
                </el-dropdown-item>
                <div class="func-list">
                    <el-row>
                        <el-col :span="8">
                            <el-dropdown-item command="vip">
                                <p class="down-icon"><i class="el-icon-s-flag"></i></p>
                                <p class="down-label">会员中心</p>
                            </el-dropdown-item>
                        </el-col>
                        <el-col :span="8">
                            <el-dropdown-item command="personal">
                                <p class="down-icon"><i class="el-icon-user-solid"></i></p>
                                <p class="down-label">资料修改</p>
                            </el-dropdown-item>
                        </el-col>
                        <el-col :span="8">
                            <el-dropdown-item command="resources/list">
                                <p class="down-icon"><i class="el-icon-s-promotion"></i></p>
                                <p class="down-label">我的发布</p>
                            </el-dropdown-item>
                        </el-col>
                        <el-col :span="8">
                            <el-dropdown-item command="resources/collect">
                                <p class="down-icon"><i class="el-icon-star-on"></i></p>
                                <p class="down-label">我的收藏</p>
                            </el-dropdown-item>
                        </el-col>
                        <el-col :span="8">
                            <el-dropdown-item command="resources/download">
                                <p class="down-icon"><i class="el-icon-s-order"></i></p>
                                <p class="down-label">下载历史</p>
                            </el-dropdown-item>
                        </el-col>
                        <el-col :span="8">
                            <el-dropdown-item command="logout">
                                <p class="down-icon"><i class="el-icon-switch-button"></i></p>
                                <p class="down-label">退出</p>
                            </el-dropdown-item>
                        </el-col>
                    </el-row>
                </div>
            </el-dropdown-menu>
        </el-dropdown>
        <template v-else>
            <span class="auth-btn" @click="login">登录</span>
            <span class="auth-btn" @click="register">注册</span>
        </template>
    </div>
</template>

<script>
import DefaultAvatar from "../../assets/imgs/default_avatar.png";
import VipIcon from "../../assets/imgs/vip_icon.png";
import VipBigIcon from "../../assets/imgs/vip_big_icon.png";
export default {
    props: {
        // 下拉框的皮肤 dark light
        theme: {
            type: String,
            default: "dark"
        }
    },
    data() {
        return {
            DefaultAvatar,
            VipIcon,
            VipBigIcon
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo;
        },
    },
    methods: {
        login() {
            if (this.$route.meta.noSideBar) {
                return this.$router.push({
                    path: "/login",
                    query: this.$route.query,
                });
            }
            this.$store.dispatch("toggleUserLoginModal", true);
        },
        register() {
            if (this.$route.meta.noSideBar) {
                return this.$router.push({
                    path: "/register",
                    query: this.$route.query,
                });
            }
            this.$store.dispatch("toggleUserLoginModal", true);
        },
        checkMenu(menuName) {
            console.log(menuName);
            if (!menuName) return;
            if (menuName == "logout")
                return this.$store.dispatch("userLogout").then(() => {
                    this.$router.replace("/redirect" + this.$route.fullPath);
                });
            else this.$router.push("/" + menuName);
        },
    }
}
</script>

<style lang="scss" scoped>
.user {
    cursor: pointer;

    img {
        width: 32px;
        height: 32px;
    }

    &.dark {
        .auth-btn {
            color: #c8c7cc;
            font-size: 12px;
            margin-left: 10px;

            &:hover {
                color: #ffffff;
            }
        }
    }

    .light {
        .auth-btn {
            color: #666;
            font-size: 12px;
            margin-left: 10px;

            &:hover {
                color: #000;
            }
        }
    }
}

.avatar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0;
    min-width: 100px;

    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    span {
        font-size: 14px;
        vertical-align: middle;
        white-space: nowrap;
        margin-left: 10px;
    }

    .user-vip__icon {
        width: 24px;
        height: 24px;
        margin-left: 10px;

        img {
            width: 100%;
            height: 100%;
        }
    }
}

.func-list {
    width: 240px;

    .el-dropdown-menu__item {
        padding: 10px;
        text-align: center;

        .down-icon {
            font-size: 14px;
            line-height: 18px;

            i {
                margin-right: 0;
            }
        }

        .down-label {
            line-height: 24px;
            font-size: 12px;
        }
    }
}
</style>